<template>
  <div>
    <Loader />
    <section class="sign-in-page">
       <navbar></navbar>
        <div class="row no-gutters">
          <div class="col-md-6 col-sm-6 text-center">
            <div class="">
               <img :src="require(`../../assets/images/step-1-image.jpg`)" class="w-100 h-25">
           </div>
          </div>
          <div class="col-md-6 col-sm-6">
          <div class="py-5 sm-p-0">
          <div class="sign-in-from mt-5 sm-mt-1">
              <router-view></router-view>
            </div>
            </div>
          
          </div>
          
        </div>
           <FooterStyle1 class="iq-footer">
      <template v-slot:left>
          <p class="m-0 font-size-12">© Copyright 2021 <a href="https://womenfirstdigital.org/" target="_blank">Womenfirstdigital.org</a> All Rights Reserved.</p>
      </template>
    </FooterStyle1>
          
    
      
     </section>
  </div>
</template>
<script>
import Loader from '../../components/socialvue/loader/Loader'
import Navbar from '../../components/socialvue/navbars/NavBarStyle4'
import FooterStyle1 from '../../components/socialvue/footer/FooterStyle1'
import logo from '../../assets/images/logo-white.png'
import { socialvue } from '../../config/pluginInit'
import bgImage from '../../assets/images/user-icon.svg'
export default {
  name: 'AuthLayout1',
  components: {
    Loader,Navbar,FooterStyle1
  },
  mounted () {
    socialvue.index()
  },
  data () {
    return {
      slickOptions: {
        slidesToShow: 1,
        arrows: false
      },
      logo: logo,
      bgImageURL: bgImage
    }
  }
}
</script>
